<template>
    <page>
        <page-head>
            <app-button property="success" icon="icon-add" @click="createModal">
                {{ translate('button.Add') }}
            </app-button>
        </page-head>

        <page-body>
            <data-grid :data-source="items" :columns="columns" :action-column-width="120"
                :action-column-text="translate(translateKey + '.Label.Action')">
                <div slot="actionSlot" slot-scope="list">
                    <app-button v-if="can(permission + '.update')" @click="createModal(list.row.form)" :sm="true"
                        :title="translate('button.Edit')" icon="icon-edit-2" property="primary" />

                    <!-- <app-button
                        v-if="can(permission + '.action')"
                        :sm="true"
                        @click="action(list.row, 'active')"
                        :title="list.row.action.active ? translate('button.DeActivate') : translate('button.Activate')"
                        :icon="!list.row.action.active ? 'icon-eye-off' : 'icon-eye'"
                        property="success"
                    /> -->

                    <app-button v-if="can(permission + '.delete')" :sm="true" @click="remove(list.row.id)"
                        :title="translate('button.Delete')" icon="icon-trash-2" property="danger" />

                </div>
            </data-grid>
        </page-body>

        <modal :id="modalId" size="md">
            <modal-head>
                <modal-title>{{ currentPage.title }}</modal-title>
            </modal-head>
            <modal-body v-if="modelShow">
                <form @submit.prevent="save">
                    <grid>
                        <form-group :label="'Məhsul'" name="product_name">
                            <tree-select :async="true" :normalizer="normalizer" v-model="form.product_id"
                                :load-options="loadOptions" />
                            <!-- <form-text v-model="form.product_name"/> -->
                        </form-group>

                        <form-group v-if="form.product_name" :label="'Məhsul adı'" name="product_name">
                            <!-- <form-text v-model="form.product_name"/> -->
                            <b class="ml-2">{{ form.product_name }}</b>
                        </form-group>

                        <form-group v-if="form.product_price" :label="'Qiymət'" name="product_price">
                            <b class="ml-2">{{ form.product_price }}</b>

                            <!-- <form-text v-model="form.product_name"/> -->
                        </form-group>

                        <form-group :label="'Sayı'" name="count">
                            <form-text v-model="form.count" />
                        </form-group>


                        <app-button class="justify-center" property="success" type="submit">
                            {{ translate('button.Save') }}
                        </app-button>
                    </grid>
                </form>
            </modal-body>
        </modal>

        <modal :id="'finishMonth'" size="xs">
            <modal-head>
                <modal-title>{{ currentPage.title }}</modal-title>
            </modal-head>
            <modal-body v-if="modelShow">
                <form @submit.prevent="save">
                    <grid>
                        <form-group :label="'Aylıq Satış'" name="paid">
                            <input class="ml-2" type="checkbox" label="addsa" id="paid" value="true" v-model="form.paid">
                            <label class="ml-2" :for="'paid'">{{ (form.paid === true ? 'oldu' : 'Olmadı') }}</label>
                        </form-group>

                        <app-button class="justify-center" property="success" type="submit">
                            {{ translate('button.Save') }}
                        </app-button>
                    </grid>
                </form>
            </modal-body>
        </modal>
    </page>
</template>

<script>
/*
 * Import Components
 * */
import { mapActions, mapState } from 'vuex';
// import Products from "./Products";
import TreeSelect from '@riophae/vue-treeselect'
import { ASYNC_SEARCH } from '@riophae/vue-treeselect'

const modalId = 'createModal';
const translateKey = 'crm.PortalShops';

export default {
    name: "RevisionIndex",
    components: { TreeSelect },
    data() {
        return {
            translateKey,
            modalId,
            modelShow: false,
            photoItems: [],
            columns: [
                {
                    caption: 'Məhsul adı',
                    dataField: 'product_name',
                    show: true
                },
                {
                    caption: 'Məhsul Qiymət',
                    dataField: 'product_price',
                    show: true
                },
                {
                    caption: 'Say',
                    dataField: 'count',
                    show: true,
                    allowFiltering: false
                },
                {
                    caption: 'Tarix',
                    dataField: 'created_at',
                    show: true,
                    allowFiltering: false
                },
            ],
            normalizer(i) {
                return {
                    id: i.id,
                    label: i.name + ' ' + (i.purchase_price !== undefined ? i.purchase_price + ' AZN' : '') + ' ' + (i.pgc_price ? i.pgc_price + ' PGS' : '')
                }
            },
            form: {}
        }
    },
    computed: {
        ...mapState('PortlaShopInventoryStore', ['items']),
        ...mapState('ProductsStore', ['products']),
        permission() {
            return this.currentPage.permission;
        }
    },
    methods: {
        ...mapActions('PortlaShopInventoryStore', ['getItems', 'getItem', 'actionItem', 'deleteItem', 'setItem']),
        ...mapActions('ProductsStore', ['getSelectProducts']),
        /*
         * Form Create
         * */
        formCreate(item = {}) {
            const form = {
                id: item.id !== undefined ? item.id : 0,
                product_id: item.product_id !== undefined ? item.product_id : null, // Eğer product_id tanımlı ise al, değilse null ata
                product_name: item.product_name || null,
                product_price: item.product_price || null,
                count: item.count || null,
            }
            this.form = form;
            this.modelShow = true;
        },
        /*
         * Create Modal
         * */
        createModal(item = {}) {
            this.modal(this.modalId)
            this.modelShow = false;
            this.resetError();
            this.formCreate(item);
        },
        /*
         * Remove
         * */
        remove(id) {
            this.alert().then(r => this.deleteItem(id).then(r => this.getItems()))
        },
        /*
         * Action
         * */
        action(item, type) {
            let action = item.action[type] ? 0 : 1;
            this.actionItem({ id: item.id, type, action }).then(r => this.getItems())
        },
        /*
         * Save
         * */
        save() {
            this.setItem(this.form)
                .then(r => {
                    if (r) {
                        this.modal(this.modalId);
                        this.getItems();
                    }
                })
        },
        loadOptions({ action, searchQuery, callback }) {
            if (action === ASYNC_SEARCH) {
                this.getSelectProducts({ search: searchQuery, limit: 40 })
                    .then(r => {
                        callback(null, this.products)
                    })
            }
        },
        loadUsers({ action, searchQuery, callback }) {
            if (action === ASYNC_SEARCH) {
                this.getSelectUsers({ search: searchQuery, limit: 40 })
                    .then(r => {
                        callback(null, this.users)
                    })
            }
        },
    },

    created() {
        this.getItems();
        console.log(this.items);
    }

}
</script>

<style scoped></style>
